import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { SketchPicker } from 'react-color';
import Popup from 'reactjs-popup';

import Viewer from './Viewer';

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)

    }
    openModal (){
    this.setState({ open: true })
    }
    closeModal () {
    this.setState({ open: false })
    }

  render() {
    return (
    //   <div> 
    //     <button className="button" onClick={this.openModal}>
    //       Controlled Popup
    //     </button>
    //     <Popup
    //       open={this.state.open}
    //       closeOnDocumentClick
    //       onClose={this.closeModal}
    //     >
    //       <SketchPicker />
    //     </Popup>
    // </div>
     
      <Viewer/>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
