const GAMMA = 2.4;

function srgbToLinear1(c) {
    var v = 0.0;
    if (c < 0.04045) {
        if (c >= 0.0) v = c * (1.0 / 12.92);
    } else {
        v = Math.pow((c + 0.055) * (1.0 / 1.055), GAMMA);
    }
    return v;
}

function linearToSrgb2(c) {
    var v = 0.0;
    if (c < 0.0031308) {
        if (c > 0.0)
            v = c * 12.92;
    } else {
        v = 1.055 * Math.pow(c, 1.0 / GAMMA) - 0.055;
    }
    return v;
}

let linearToSrgb1 = c => c > 0.0031308 ? (1 + 0.055) * Math.pow(c, 1 / 2.4) - 0.055 : 12.92 * c;

function linearToSrgb(c, out) {
    var col = out || new Array(c.length);

    if (c.length > 2 && c.length < 5) {
        col[0] = linearToSrgb2(c[0]);
        col[1] = linearToSrgb2(c[1]);
        col[2] = linearToSrgb2(c[2]);
        if (col.length > 3 && c.length > 3) col[3] = c[3];
    } else {
        throw new Error('Invalid color. Expected 3 or 4 components, but got ' + c.length);
    }
    return col;
}

function srgbToLinear(c, out) {
    var col = out || new Array(c.length);

    if (c.length > 2 && c.length < 5) {
        col[0] = srgbToLinear1(c[0]);
        col[1] = srgbToLinear1(c[1]);
        col[2] = srgbToLinear1(c[2]);
        if (col.length > 3 && c.length > 3) col[3] = c[3];
    } else {
        throw new Error('Invalid color. Expected 3 or 4 components, but got ' + c.length);
    }
    return col;
}

function hexToRgb(hexColor) {
    var m = hexColor.match(/^#([0-9a-f]{6})$/i);
    if (m) {
        return [
            parseInt(m[1].substr(0, 2), 16) / 255,
            parseInt(m[1].substr(2, 2), 16) / 255,
            parseInt(m[1].substr(4, 2), 16) / 255
        ];
    } else {
        throw new Error('Invalid color: ' + hexColor);
    }
}

let scaleBytes = (color, m) => color.map(c => Math.round(linearToSrgb(srgbToLinear(c / 255) * m) * 255));

function componentToHex(c) {
    c = (c * 255.0) | 0;
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(color) {
    return componentToHex(color[0]) + componentToHex(color[1]) + componentToHex(color[2]);
}

export {
    srgbToLinear,
    linearToSrgb,
    linearToSrgb2,
    hexToRgb,
    rgbToHex
};
