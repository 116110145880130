import React, {Component} from 'react';
import { srgbToLinear, hexToRgb } from './lib/Colors';
import { TwitterPicker, CirclePicker} from 'react-color';
// import Popup from 'yarn add semantic-ui-react;
import { theme, animation, contextMenu  } from 'react-contexify';
import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';
import { Button, Grid, Header, Popup, Image, Modal} from 'semantic-ui-react';
import { linearToSrgb, linearToSrgb2, rgbToHex } from './lib/Colors';

import Animated from 'animated/lib/targets/react-dom';
import Easing from 'animated/lib/Easing'
import {MdHome, MdPrint, MdSatellite, MdScreenShare} from 'react-icons/md';
import 'semantic-ui-css/semantic.min.css';
//import { color2lib } from 'color2library';

const menuId = 'thisIsAnId';
class RightMenu extends Component {
    constructor(props) {
        super(props)
    }   

    render() {
        const styles = {
            main: {
                backgroundColor: 'transparent',
                position: 'absolute',
                margin: 15,
                //bottom: 0,
                right: 0,
                width: 40,
                height: 40,
            },
            button: {
                color: 'white',
                width: '100%',
                height:  '100%',
                filter: 'drop-shadow( 0px 0px 8px rgba(0, 0, 0, .7))'
            }
        }
        return (
            <div style={styles.main}>
                <MdHome style={styles.button} onClick={this.props.show('blurring')} className='grow'/>
                <MdPrint style={styles.button} onClick={
                    //() => this.props.iframe.contentWindow.print() } className='grow'/>
                    () => window.print() } className='grow'/>
                <MdSatellite style={styles.button} onClick={this.props.show('inverted')} className='grow'/>
                <MdScreenShare style={styles.button} onClick={this.props.show(true)} className='grow'/>
            </div>
        );
    }
}

class ProgressCircle extends Component {
    render() {
        var style = {   
            bottom: 0,
            position: "absolute",
            margin: 15,
        };

        var textStyle = {
            textShadow: '0 0 10px #00000067',
            MozUserSelect:'none',
            WebkitUserSelect:'none',
            msUserSelect:'none', 
        };

        var circleStyle = {
            boxShadow: "0 0 10px #00000067",
            marginRight: 10,           
            display:"inline-block",
            backgroundColor: this.props.bgColor,
            borderRadius: "50%",
            width: this.props.size,
            height: this.props.size,
            borderColor: this.props.borderColor,
            borderStyle: 'solid',
            background: `linear-gradient(transparent ${100-this.props.pct}%, ${this.props.bgColor} ${100-this.props.pct}%)`
          };
          
        const elements = [];

        var factor = 100 / (this.props.childs+1);
        var full = Math.floor(this.props.pct/factor);
        var part = this.props.pct/factor - full;

        for (var i = 0; i < this.props.childs; i++) { 
            var n = Object.assign({}, circleStyle);
           
            var value = 0;
            if(full == i) value = part * 100;
            else if(full > i) value = 100;

            n.background = `linear-gradient(transparent ${100-value}%, ${this.props.bgColor} ${100-value}%)`;
            elements.push(n); 
        }

        return (  
            <div style={style}>
                <font style={textStyle} color={this.props.borderColor}>{this.props.text}</font>
                <div>{elements.map((value, index) => {
                    return <div key={index} style={value}></div>
                })}</div>     
            </div>                 
        );
    }
}

const styles = {
    circle: {
        width: 50,
        height: 50,
        borderRadius: 50/2,
        backgroundColor: 'transparent',
        position: 'absolute',
        bottom: 0,
        margin: 15,
        border: "2px solid red",
    }
}
// .circle {
//   border: 1px solid #CCC;
//   border-radius: 19px;
//   display: inline-block;
// }

// .inner {
//   background-color: #F80;
//   border-radius: 15px;
//   margin: 3px;
//   height: 30px;
//   width: 30px;
// }
const MyMenu = () => (
    <Menu id={menuId} theme={theme.dark} animation={animation.zoom}>
      <Item>Copy</Item>
      <Separator />
      <Item>Paste</Item>
      <Item>Cut</Item>
    </Menu>
    );

class Viewer extends Component {
    constructor(props) {
        super(props)
        this.state = { open: false }
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)

        }
        
    openModal() {
        this.setState({ open: true })
    }
    closeModal() {
        this.setState({ open: false })
    }

    animatedValue = 0

	state = {
        materials: null,
        list: null,
		nodesData: null,
		sceneGraph: null,
		animationsData: null,
        api: null,
        initalMaterial: null,
        clickEvent: null,
        colors: null,
        clickedMat: null,
        progress: 0,
        open: false
    }

    handleChangeComplete = (color) => {
        var material = this.state.clickedMat;
        //this.setState({ background: color.hex });
        this.setState( { isOpen: false } );        

        this.setMaterialColor(material, color.hex, material.channels.DiffusePBR.factor);

        var l = this.state.list.find(function(cur) {
            if (cur.mat.id == material.id) {
                return cur;
            }});

        //this.animatedValue.setValue(0);
        l.isColored = true;
        console.log("progress => " + this.animatedValue);
        //this.state.progress.setValue(0);

        const arrAvg = this.state.list.reduce((a,b) => a + (b.isColored ? 1:0 ), 0) / this.state.list.length;
        const context = this;

        setTimeout(function() { context.setState({progress: arrAvg * 100}); }, 500);

        // Animated.timing(this.animatedValue,{
        //       toValue: arrAvg * 100,
        //       duration: 1000,
        //     }
        //   ).start();

        //this.state.progress = arrAvg * 100;
    };
    
    handleOpen = () => {
        this.setState({ isOpen: true });
    
        // this.timeout = setTimeout(() => {
        //   this.setState({ isOpen: false })
        // }, timeoutLength)
      }
    
      handleClose = () => {
        this.setState({ isOpen: false })
        clearTimeout(this.timeout)
      }

	copyMaterial(from, to){
		from.stateSetID = to.stateSetID;

		this.state.api.setMaterial(from, function () {
			console.log('Material updated');
		});
	}

    getMaterialByName(materialName) {
        if (!this.state.materials) {
            return null;
        }

        return this.state.materials.reduce(function(acc, cur) {
            if (cur.name === materialName) {
                return cur;
            }
            return acc;
        }, null);
    }

    setMaterialColor(material, hexColor, factor) {
        const linearColor = srgbToLinear(hexToRgb(hexColor));

        material.channels.AlbedoPBR.color = linearColor;
        // material.channels.DiffusePBR.color = linearColor;
        // material.channels.DiffuseColor.color = linearColor;
        // material.channels.DiffuseColor.factor = factor || 1.0;
        // material.channels.AlbedoPBR.texture = undefined;
        // material.channels.DiffusePBR.texture = undefined;
        // material.channels.DiffuseColor.texture = undefined;

        this.state.api.setMaterial(material, function(err, result) {
            if (err) {
                console.error(err);
            }
        });
    }   

    componentDidMount() {      
        var urlid = '9f8a7bd262e049899b03d6f439975b68';
        var client = new window.Sketchfab('1.4.2', this.iframe);
        var context = this;

        // const color2library = require("color2library");

        // color2library({
        //     libraries: ['pantone'],
        //     color: '#111111' 
        //   }, (colors) => {
        //     console.log(colors);
        //   })
		
        client.init(urlid, {
            camera: 0,
            preload: 1,
            ui_controls: 0,
            ui_infos: 0,
            ui_watermark: 0,
            animation_autoplay: 0,
            ui_stop: 0,
            transparent: 0,
            scrollwheel: 0,
            double_click: 0,
            orbit_constraint_pitch_down : 0.0,
            annotation : 0,
            annotations_visible : 0,
            //orbit_constraint_zoom_in: 14,
            //orbit_constraint_zoom_out: 50,
            //debug3d: 1,

            success: (api) => {
                api.start();
                api.addEventListener('viewerready', function() {
                    //api.hideAnnotation(0);
					//api.hideAnnotation(1);
					context.state.api = api;

                    //api.gotoAnnotation(0);

                    api.getNodeMap( function(error, nodes) {
						if (error) return console.log(error);
						
                        console.log(nodes);
                        context.state.nodesData = nodes;
                    });

                    api.getSceneGraph( function(error, result) {
                            if (error) return console.log(error);

                            context.state.sceneGraph = result;
                            console.log(result);
                    });

					api.getMaterialList(function (error, materials) {
						if (error) return console.log(error);

                        //console.log(materials);
                        context.state.materials = materials;
                        context.state.list = materials.map(x => {
                            return ({ mat:x, isColored:false });
                          });

                        console.log(context.state.list);

                        const initalMaterial = context.getMaterialByName("color");
                        context.state.initalMaterial = initalMaterial;
                        
                        const rgb = linearToSrgb(initalMaterial.channels.AlbedoPBR.color);

                        const defaultColor = '#'+ rgbToHex( rgb );

                        context.state.colors = new Map();

                        materials.forEach(element => {
                            if( element != context.state.initalMaterial ){                               
                                var rgb = linearToSrgb(element.channels.AlbedoPBR.color);

                                var hexColor = rgbToHex( rgb );                               
                                context.state.colors.set( element.name, { color: hexColor, factor: element.channels.AlbedoPBR.factor } );
                                //context.copyMaterial(context.state.initalMaterial, element);

                                context.setMaterialColor(element, defaultColor, element.channels.AlbedoPBR.factor);
                            }                          
                        });
                        
					});  
					
					api.addEventListener('click', function (e) {
                        if(e.material == null) return;                       

                        let found = context.state.colors.get(e.material.name);

                        const color2library = require("color2library");

                        color2library({
                            libraries: ['hks'],
                            color: '#' + found.color
                          }, (colors) => {
                            const m = colors.map(c => c.color).slice(0,4);
                            m.unshift('#' + found.color);
    
                            context.setState({ isOpen: true, x: e.position2D[0], y: e.position2D[1] , distColors: m, clickedMat: e.material });

                            console.log(colors);
                          });
                          

                        //var colors = window.PMSColorMatching(found.color, 48);                        

                        //const m = colors.map(c => '#' + window.PMS2RGB(c)).slice(0,4);
                       
                        //context.setState({ open: !context.state.open })

                    }, { pick: "slow" });
                    document.onclick = context.handleEvent;
                }); 
            },
            error: function onError() {
                console.log('Viewer error');
            }
        });        
    }

   
    handleEvent = e => {
        this.state.clickEvent = e;
      };

    show = dimmer =>
        () => this.setState({ dimmer, open: true })
    close = 
        () => this.setState({ open: false })

    render() {
        const { open, dimmer } = this.state
        // const { x, y, show : menuShow } = this.x;
        const menuStyle = {
            top: this.state.y,
            left: this.state.x,
            position: "absolute",
            borderStyle: "none",
            borderRadius: 0,
            opacity: 1,
            padding: 0,
            background: 'transparent',
            boxShadow: "0 0 0 0 rgba(34,36,38,0), 0 0 0 0 rgba(34,36,38,0)"
          };

        var headerStyle = {
            textShadow: '0 0 10px #00000067',
            margin: 15,
            position: 'absolute',
            textColor: 'white',
            fontSize: '20pt',
            MozUserSelect:'none',
            WebkitUserSelect:'none',
            msUserSelect:'none',    
        };
        var annotationButtonStyle ={
            boxShadow: '0 0 0 rgba(213,43,30,0.4)',
            //backgroundImage: 'url(img/rotate-fender.svg)',
            margin: 15,
            position: 'absolute',
            height: 60,
            width: 60,    
            borderRadius: '50%',
            background: 'transparent url(img/rotate.svg) no-repeat center',  
            backgroundSize: '84% auto',       
            //box-shadow: 0 0 0 rgba(255,51,0,0.4);
            //animation: pulse-shadow 2s infinite;
            bottom: 0,
            right: 0,
        };

        const animatedValue = new Animated.Value(0);
        return (  
            <div class="configurator-container">
                <iframe id="sfab" ref={(iframe) => {
                    this.iframe = iframe;
                    }} src="about:blank" title="Sketchfab Viewer" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true" >
                </iframe>
                {/* <div class="overlay" ></div> */}
                {/* <MyMenu/> */}
                {/* <button className="button" onClick={this.openModal}>
                    Controlled Popup
                </button> */}
                <Popup
                    // trigger={<Button content='Open controlled popup' />}
                    content={<CirclePicker colors={this.state.distColors} onChangeComplete={ this.handleChangeComplete }/>}
                    on='click'
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    onOpen={this.handleOpen}
                    position='top left'
                    style={menuStyle}
                    basic
                />
                
                {/* <Popup
                    open={this.state.open}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <TwitterPicker  />
                </Popup> */}
                <font style={headerStyle} color='white'>COLOR STORY</font>
                <ProgressCircle pct={this.state.progress} text='COLORED' childs={5} size={30} bgColor='white' borderColor='white' />  
                <div style={annotationButtonStyle} className='grow' ></div> 
                <RightMenu show={this.show} iframe={this.iframe}/>
{/* 
                <Button onClick={this.show(true)}>Default</Button>
                <Button onClick={this.show('inverted')}>Inverted</Button>
                <Button onClick={this.show('blurring')}>Blurring</Button> */}

                <Modal dimmer={dimmer} open={open} onClose={this.close}>
                    <Modal.Header>Select a Photo</Modal.Header>
                    <Modal.Content image>
                        <Image wrapped size='medium' src='/img/deadspace-cover.jpg' />
                        <Modal.Description>
                            <Header>Default Profile Image</Header>
                            <p>We've found the following gravatar image associated with your e-mail address.</p>
                            <p>Is it okay to use this photo?</p>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='black' onClick={this.close}>
                            Nope
                    </Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content="Yep, that's me"
                            onClick={this.close}
                        />
                    </Modal.Actions>
                </Modal>
            </div>             
           
        );
    }
}

export default Viewer;